import { render, staticRenderFns } from "./BonusPage.vue?vue&type=template&id=8d38520a"
import script from "./BonusPage.vue?vue&type=script&lang=js"
export * from "./BonusPage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Theme4MobilePagesBonusPage: require('/var/app/components/Theme4/Mobile/Pages/BonusPage.vue').default,Theme4DesktopPagesBonusPage: require('/var/app/components/Theme4/Desktop/Pages/BonusPage.vue').default})
